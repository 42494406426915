import moment from 'moment'

export const isBrowser = () => typeof window !== 'undefined'

const UserKey = 'user'
export const LoginPath = '/app/login'

// We will store the last logged in time in localStorage
const setLoggedIn = () => window.localStorage.setItem(UserKey, new Date())

export const handleLogin = password => {
  password = password.toLowerCase()
  if (
    password === `bubby` ||
    password === 'baby' ||
    password === 'amorcita' ||
    password === 'amor'
  )
    setLoggedIn()
  return false
}

export const isLoggedIn = window => {
  if (!window) return false
  if (!isBrowser) return false
  const lastLoggedIn = window.localStorage.getItem(UserKey)
  if (!lastLoggedIn) return false
  // If the current date is more than 5 minutes later than lastLoggedIn, then force
  // user to relog
  const minuteDiff = moment().diff(moment(lastLoggedIn), 'minutes')
  if (minuteDiff > 5 || minuteDiff < 0) return false
  return true
}

export const logout = callback => {
  setLoggedIn()
  callback()
}
